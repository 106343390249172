export const siteInfo = {
  name: "Boritta",
  // navColor: "#E19898",
  navColor: "#161A30",
  secondary: "#41BCE1",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.5,
  minimum_price: 1000,
  sitekey: "boadsekefoauac",
  minimum_quantity: 3,
  error: "#C4161C",
  tableHeader: "#E9F0EE",
};
